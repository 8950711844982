
import mixPreset from '@/pages/mixins/policy/mix.policy.preset'

const Names = mixPreset.Names
const Parts = mixPreset.Parts

const NavHome = {
  text: '首页',
  disabled: false,
  href: '/',
}

const NavSelf = {
  text: '创新政策',
  disabled: false,
  href: '/policy',
}

const NavCurrent = {
  text: '政策集锦',
  disabled: true,
  href: '',
}

export default {
  data () {
    return {
      detailName: mixPreset.DetailName,
      listName: mixPreset.ListName,
      navigated: true,
      navHome: NavHome,
      navSelf: NavSelf,
      navCrumbs: [
        NavHome,
        NavSelf,
        NavCurrent
      ],
      navPage: {
        current: 1,
        length: 15,
        totalVisible: 7
      },
      catListKeys: [
        Names.COLLECTION,
        Names.UNDERSTAND
      ],
      currentCategory: {},
      navCategory: {
        title: '创新政策',
        name: 'policy',
        active: 0,
        items: [
          Parts[Names.COLLECTION],
          Parts[Names.UNDERSTAND],
        ]
      }
    }
  },
  methods: {
    getCategory (name) {
      let result = null
      const cats = this.navCategory.items
      for (const i in cats) {
        const cat = cats[i]
        if (cat.name === name) {
          result = cat
          break
        }
      }
      return result
    },
    activeCategory (name) {
      let result = 0
      const cnt = this.navCategory.items.length
      for (let i = 0; i < cnt; i++) {
        const item = this.navCategory.items[i]
        if (item.name === name) {
          result = i
          break
        }
      }

      this.navCategory.active = result
    },
    focusCategory (item) {
      this.navCrumbs = []
      this.navCrumbs.push(
        { ...this.navHome },
        { ...this.navSelf },
        {
          text: item.title,
          disabled: true,
          href: '',
        }
      )

      this.currentCategory = this.getCategory(item.name)
      Object.assign(this.navPage, this.currentCategory.pagination)
      this.navigated = item.navigated
    },
    onCategory (ev) {
      const item = ev.item
      this.gotoCategory(item)
    },
    gotoCategory (item) {
      this.focusCategory(item)
      this.activeCategory(item.name)
    },
    toCategory (name) {
      const item = this.getCategory(name)
      this.currentCategory = item

      this.navCrumbs = []
      this.navCrumbs.push(
        { ...this.navHome },
        { ...this.navSelf },
        {
          text: item.title,
          disabled: true,
          href: '',
        }
      )

      Object.assign(this.navPage, this.currentCategory.pagination)
      this.navigated = item.navigated
      this.activeCategory(name)
    }
  }
}

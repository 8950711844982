
import api from '@/api/co.api'
import app from '@/api/co.app'
import act from '@/api/co.action'
import notice from '@/api/co.notice'

import mixPolicyCategory from './mix.policy.category'

export default {
  mixins: [mixPolicyCategory],
  data () {
    return {
      limitCount: 6,
      notSearch: {
        type: '',
        value: ''
      },
    }
  },
  methods: {
    onNotice (ev, category) {
      // console.log('ev: %o, category: %o', ev, category)
      act.navigate({
        that: this,
        pagename: this.detailName,
        item: ev.item,
        category
      })
    },
    toNotices (ev, category) {
      act.navigate({
        that: this,
        pagename: this.listName,
        item: {
          type: 'notices'
        },
        category
      })
    },
    onSearchKeyDown (ke) {
      if (ke.key === 'Enter') {
        this.onSearch()
      }
    },
    clearSearch () {
      this.toSearch({})
    },
    onSearch () {
      const search = this.notSearch.value || ''
      let searchType = ''
      if (search) {
        searchType = 'title'
      }
      this.toSearch({ search, searchType })
    },
    toSearch ({
      search = '',
      searchType = ''
    }) {
      const params = this.currentCategory.params
      const data = params.data

      params.reload = true
      data.offset = 0
      data.search = search
      data.searchType = searchType
      api.http.getItems(params)
    },
    changePagination (number, name) {
      const cat = this.getCategory(name)
      const params = cat.params
      const data = cat.params.data
      const limit = parseInt(data.limit, 10) || 10
      const index = parseInt(number, 10) || 1

      cat.pagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      params.initial = false
      api.http.getItems(params)
      // console.log('number: %o, name: %o', number, name)
    },
    initCategoryParams (category = null) {
      const me = this
      if (!category) {
        return
      }
      const executing = function () {
      }

      const executed = function (res) {
        // console.log('res: %o', res)
        const data = category.params.data
        const pagination = category.pagination
        const limit = parseInt(data.limit) || 10
        pagination.length = Math.ceil(res.total / limit)

        if (category.name === me.currentCategory.name) {
          Object.assign(me.navPage, pagination)
        }
        if (category.params.initial === true) {
          category.latest = []
          const len = category.items.length
          const cnt = len > me.limitCount ? me.limitCount : len
          for (let i = 0; i < cnt; i++) {
            const item = category.items[i]
            category.latest.push(item)
          }
        }
        // console.log('pagination: %o', pagination)
      }
      category.params = notice.getParams({
        siteId: app.init.siteId,
        type: category.type,
        topicId: category.topicId,
        categoryId: category.categoryId,
        limit: '10',
        caches: category.items,
        executing,
        executed
      })
    },
    initParams () {
      // const me = this
      let startCat = ''
      let ind = 0
      const keys = this.catListKeys
      const cats = this.navCategory.items
      for (const i in cats) {
        const cat = cats[i]
        ind = parseInt(i, 10) || 0
        if (keys.indexOf(cat.name) !== -1) {
          if (ind === 0) {
            startCat = cat.name
          }
          this.initCategoryParams(cat)
        }
      }
      if (startCat) {
        this.toCategory(startCat)
      }
    },
    loadNotices (category = null, reload = true) {
      if (!category) {
        return
      }
      const params = category.params
      if (params) {
        params.reload = reload
        params.initial = true
        api.http.getItems(params)
      }
    },
    loadData (name = '', reload = true) {
      if (name) {
        const cat = this.getCategory(name)
        this.loadNotices(cat, reload)
      } else {
        const cats = this.navCategory.items
        for (const i in cats) {
          const cat = cats[i]
          this.loadNotices(cat, reload)
        }
      }
    }
  }
}

<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
    />
    <land-section
      id="noticecenter"
      space="40"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
            md="3"
            class="hidden-sm-and-down"
          >
            <land-categories
              :title="navCategory.title"
              :items="navCategory.items"
              @click="onCategory"
            />
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <div
              class="d-flex flex-row justify-space-between align-center pt-1 pb-5"
            >
              <v-breadcrumbs
                :items="navCrumbs"
                large
                class="pa-0"
              />
              <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
              />
            </div>
            <v-row
              class="pa-5 white"
            >
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="notSearch.value"
                  append-icon="mdi-magnify"
                  filled
                  clear-icon="mdi-close-circle"
                  clearable
                  flat
                  solo
                  rounded
                  hide-details="auto"
                  label=""
                  placeholder="查找关键字"
                  type="text"
                  class="ma-0 px-5"
                  @click:append="onSearch"
                  @click:clear="clearSearch"
                  @keydown="onSearchKeyDown"
                />
                <land-plain-notices
                  :items="currentCategory.items"
                  back-color="white"
                  card-color="grey lighten-4"
                  padding="pa-5"
                  :indicator="currentCategory.indicator"
                  :indicator-small="false"
                  @click="onNotice($event, currentCategory)"
                />

                <div class="d-flex flex-row mt-4">
                  <v-pagination
                    v-model="navPage.current"
                    :length="navPage.length"
                    :total-visible="navPage.totalVisible"
                    @input="changePagination($event, currentCategory.name)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <land-notice-category-drawer
        :drawer="drawer"
        :category="navCategory"
        @click="onCategory"
      />
    </land-section>
    <land-contact-way
      dark
      back-color="blue-grey darken-4"
    />
  </section>
</template>

<script>
  import mixPolicyBanner from '@/pages/mixins/policy/mix.policy.banner'
  import mixPolicyMain from '@/pages/mixins/policy/mix.policy.main'

  export default {
    metaInfo: { title: '创新政策' },
    mixins: [
      mixPolicyBanner,
      mixPolicyMain
    ],
    data () {
      return {
        drawer: false,
      }
    },
    created () {
      this.setCrumbs(null)

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initParams()
      this.loadData()
    },
  }
</script>
